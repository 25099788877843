<template>
  <v-card
    class="buy-card"
    elevation="4"
    border-radius="5"
    align="center"
    justify="center"
    max-width="360px"
  >
    <v-layout class="pa-1" justify-end>
      <v-btn
        color="pink"
        dark
        outline
        absolute
        small
        fab
        @click="closeModal"
      >
        <v-icon>fas fa-close</v-icon>
      </v-btn>
    </v-layout>
    <h2 style="color:black;" class=" mt-3 font-weight-medium text-center">
      Gain Free Access
    </h2>
    <v-layout justify-center class="px-2">
      <v-img
        class="mt-1"
        width="auto "
        :src="img"
        contain
      />
    </v-layout>
    <v-divider class="mt-2" />

    <div class="text-center mt-2 pb-2">
      <v-dialog
        v-model="dialog"
        transition="dialog-top-transition"
        hide-overlay
        width="auto "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#FF5936"
            large
            class="mb-2 text-center font-weight-regular"
            v-bind="attrs"
            v-on="on"
          >
            <h2 style="color:white">
              Log in
            </h2>
          </v-btn>
        </template>
        <template>
          <LoginDialog :status="dialog" @close="closeModal" />
        </template>
      </v-dialog>
    </div>
    <p style="color:black;" class="font-weight-medium text-center">
      Not signed up? <a style="color:#FF5936;" @click="enrollFunc()">Sign up </a>
    </p>
  </v-card>
</template>
<script>
import LoginDialog from '../SocialLoginDialog.vue';
  export default {
    name: "Buy",
    components: {
      LoginDialog
    },
    props: {
      img: {
        type: String,
        default: '',
        required: true
      },
      headline: {
        type: String,
        default: '',
        required: true
      },

    },
    data() {
      return {
        dialog:false
      };
    },
    methods:{
      closeModal(){
        this.$emit("close");
      }
    }
}
</script>
<style lang="scss">
@import '@/styles/screen-sizes.scss';
</style>
